<template>
  <main>
    <make-banner height="600" :id="23"></make-banner>
    <recomend-list></recomend-list>
    <!-- 促销 -->
    <article
      class="activity row-between core"
      v-if="this.$store.getters.commonData.mall_type == 3"
    >
      <!-- 限时折扣 -->
      <router-link
        to="/discount"
        class="activity-item activity-r pr"
        :style="`background-image: url(${config.discount_bgimage})`"
      >
      </router-link>
      <!--  促销满减 -->
      <router-link
        to="/reduction"
        class="activity-item activity-l pr"
        :style="`background-image: url(${config.sales_bgimage})`"
      >
      </router-link>
    </article>
    <module-list></module-list>
  </main>
</template>

<script>
import RecomendList from "../components/RecomendList";
import ModuleList from "../components/ModuleList";
import { mapState } from "vuex";
export default {
  name: "Index",
  components: {
    "recomend-list": RecomendList,
    "module-list": ModuleList,
  },
  data() {
    return {
      floorGoodList: [], //楼层数据
      indexData: {}, //首页数据
    };
  },
  computed: {
    ...mapState(["config"]),
  },
  created() {
   
    this.getIndex(); 
    console.log(
      "商城类型" + JSON.stringify(this.$store.getters.commonData.mall_type)
    );
  },
  methods: {
    /**
     * @description 获取首页数据
     * @return void
     */
    getIndex() {
      this.$api.getIndexDataApi().then((res) => {
        this.indexData = res.data;
      });
    },
    /**
     * @description 获取首页热门关键词
     * @return void
     */
    getSearchList() {
      this.$api.getSearchListApi().then((res) => {
        this.serachList = res.data;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
// 促销
.activity {
  margin-bottom: 67px;
  .activity-item {
    width: 592px;
    height: 340px;
    background-size: cover;
    background-position: center;
    .item-pro {
      width: 390px;
      height: 260px;
      top: 50%;
      transform: translateY(-50%);
      border: 4px solid $-color-primary;
      padding: 0 18px;
      img {
        width: 284px;
        height: 190px;
      }
    }
    .item-info {
      width: 200px;
      height: 100px;
      top: 50%;
      transform: translateY(-50%);
      right: 40px;
      background-color: $-color-primary;
      padding-right: 30px;
      align-items: flex-end;
      line-height: 1;
    }
    .info-title {
      font-size: 28px;
      img {
        width: 32px;
        height: 32px;
        margin-right: 14px;
      }
    }
    .info-subTitle {
      img {
        width: 8px;
        height: 5px;
        transform: rotate(-90deg);
      }
    }
    &.activity-l {
      .item-pro {
        align-items: flex-end;
        border-color: #38888b;
        right: 0;
      }
      .item-info {
        left: 40px;
        background-color: #38888b;
      }
    }
  }
}
</style>
